.home-hero {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  gap: 50px;
  align-items: center;
  overflow: hidden;
  margin-bottom: 5rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    height: 450px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $gray-1;
  }

  .hero__left {
    margin-left: 200px;
  }

  .hero__right {
    max-width: 1000px;
  }

  .hero__infos {
    display: block;
    font-size: rem(18);
    text-transform: uppercase;
    color: $color-1;
    margin-top: 1rem;
  }

  .items {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 120px);
    text-align: center;
    justify-content: center;
    gap: 50px;
    margin-top: 3rem;
  }

  .item {
    text-align: center;
    &:hover .item__img {
      background: $color-1;
    }
  }

  .item__img {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;

    width: 90px;
    height: 90px;
    background: rgb(122, 122, 122);
    background: linear-gradient(
      90deg,
      rgba(122, 122, 122, 1) 0%,
      rgba(48, 48, 48, 1) 100%
    );
    border-radius: 50%;
    box-shadow: 0 10px 50px rgba($color: #000000, $alpha: 0.47);
  }

  .item__title {
    display: block;
    margin-top: rem(8);
    font-size: rem(16);
    font-weight: 300;
  }

  .swiper-wrapper {
    width: 100%;
    // height: 550px;
    // margin-bottom: rem(30);
  }

  .swiper__slide {
    position: relative;
    padding-bottom: 2rem;

    img {
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
      //   display: block;
    }
  }

  .hero__content {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translateY(-50%);
    width: 50%;
    // background-color: rgba($color: $color-light, $alpha: 0.4);
    color: white;
  }

  .hero__title {
    display: block;
    font-size: rem(25);
  }

  .hero__description {
  }

  // ------------------------------------------------------------- NAVIGATION
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    font-weight: bold;
    transform: scale(1.1);

    &:hover {
      color: $color-1;
      // text-shadow: 0 15px 15px rgba($color: #000000, $alpha: 1);
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 30px;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 30px;
  }

  // ------------------------------------------------------------- PAGINATION

  .swiper-pagination {
    display: flex;
    align-items: center;
    // justify-content: center;
    bottom: rem(16) !important;
    // bottom: calc(30px - 8px);
  }

  .swiper-pagination-bullet {
    margin: 0 rem(10) !important;
    width: 94px;
    height: 5px;
    background-color: $gray-5;
    opacity: 0.6;
    border-radius: 0;
    // box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);

    &.swiper-pagination-bullet-active {
      // width: 20px;
      // height: 20px;
      background-color: $color-1;
      opacity: 1;
    }
  }
  .hero__garanty {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    img {
      margin-left: 2rem;
    }
  }
}

@include breakpoint(1600) {
  .home-hero {
    gap: 20px;
  }
  .home-hero .hero__left {
    margin-left: 2rem;
  }
  .home-hero .items {
    gap: 20px;
    grid-template-columns: repeat(4, 115px);
  }
}

@include breakpoint(1280) {
  .home-hero {
    display: block;
    margin: 2rem 0;
    padding: 0 1rem;
  }
  .home-hero .hero__left {
    margin-left: 0;
    text-align: center;
  }
  .home-hero .items {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    max-width: 620px;
    margin: 2rem auto;
  }
}

.swiper__realisations {
  overflow: hidden;
  // position: absolute;
  .swiper-slide {
    width: auto;
  }
}
