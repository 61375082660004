.all-realisations {
  margin: 8rem 0;
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 20px;
    grid-auto-flow: dense;
  }

  .card {
    position: relative;
  }
  .card__info {
    position: absolute;
    display: block;
    z-index: 1;
    top: 10px;
    left: 10px;
    font-size: rem(24);
    line-height: 1.2;
    color: white;
    text-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.7);
  }

  .card img {
    display: block;
  }

  .card__ref {
    position: absolute;
    display: block;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    background-color: rgba($color: white, $alpha: 0.5);
    padding: 7px 14px;
    font-size: rem(22);
    font-weight: bold;
    text-transform: uppercase;
  }
}

@include breakpoint(800) {
  .all-realisations .cards {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .all-realisations .card__ref {
    font-size: rem(14);
    padding: 4px 8px;
  }
}
