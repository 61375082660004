/*
************* BTN PRIMARY
* ===================================
*/

.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: 3px solid transparent;
  color: white;
  height: initial;
  background-color: $color-dark;
  // @include shadow-1;
  font-size: rem(19);
  line-height: 1;
  font-weight: 500;
  padding: 8px 16px;
  // transition: background 200ms, color 200ms;

  &::after {
    @include icon("\f0da");
    margin-left: rem(7);
  }

  &:hover {
    background-color: rgba($color: $color-1, $alpha: 1);
    transition: all 200ms;
    border: 3px solid transparent;
  }

  &:active,
  &:focus {
    background-color: $color-1;
    color: white;
  }
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  touch-action: manipulation;
  font-family: $font-normal;
}

/*
************* BTN SECONDARY
* ===================================
*/

.btn-2 {
  background-color: $color-light;
  color: $color-1;
  border: 0;

  &:hover {
  }

  &:active,
  &:focus {
  }
}

/*
************* DISABLED
* ===================================
*/
.btn[disabled] {
  cursor: default;
  opacity: 0.1;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-1;
    color: $color-light;
  }
}

/*
************* BTN TOP
* ===================================
*/

.btn__top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(150px);
  z-index: 1000;

  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  .btn__top__link {
    // background-color: $gray-6;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $color-light;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.55);
    border: 3px solid $color-light;
    transition: all 0.2s;

    i {
      font-size: 2rem;
    }
  }

  &:hover {
    .btn__top__link {
      background-color: $color-1;
      color: white;
    }
  }
}
.btn__top--active {
  transform: translateY(0);
  opacity: 1;
}
