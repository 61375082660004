.footer {
  position: relative;
  background-color: #454545;
}

.footer__container {
  display: grid;
  grid-template-columns: 1fr 1fr 200px 1fr;
  grid-auto-flow: dense;
  gap: 50px;
  align-items: stretch;
  padding: 2rem;
}

// --------------------------------------------------------- items
.footer__item {
  color: white;
}

// --------------------------------------------------------- title
.footer__title,
.footer__item--menu ul::before {
  display: block;
  font-size: rem(20);
  color: $color-1;
  margin-bottom: 1rem;
  font-weight: bold;
}

// --------------------------------------------------------- logo
.footer__item--logo {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .item__sociaux {
    text-align: center;
    font-size: rem(40);
  }
}

// --------------------------------------------------------- navigation
.footer__item--menu {
  ul::before {
    content: "Navigation";
  }
  //------------------------------------------------------ li
  li {
    margin-left: rem(10);
  }

  //------------------------------------------------------ a
  a {
    display: block;
    font-size: rem(16);
    font-weight: 500;
    transition: all 200ms;
    padding: 2px 0;
  }

  li.active a {
    color: $color-1;
  }
  //------------------------------------------------------ hover
  a:hover {
    color: $color-1;
  }
}

// --------------------------------------------------------- contact
.footer__item--contact {
  li {
    margin: rem(10) 0;
  }
}

.contact__link {
  display: flex;
  align-items: center;

  i {
    margin-right: rem(8);
    font-size: rem(26);
    text-align: center;
    width: 30px;
    line-height: 30px;
  }

  address,
  span {
    font-size: rem(16);
  }
}

// --------------------------------------------------------- horaires

.footer__item--horaires {
  .horaires__day {
  }
}

// --------------------------------------------------------- Mentions
.footer__mentions {
  background-color: white;
  text-align: center;
  padding: rem(10) 1rem;

  p {
    padding: 0;
  }
}

// .footer__title::before {
//   display: inline-flex;
//   font-family: $font-awe;
//   content: "\f004";
// }

// ----------------------------------- Responsive

@include breakpoint(980) {
  .footer__container {
    display: block;
    text-align: center;
    padding: 2rem 1rem;
  }

  .footer__item {
    margin: 2rem 0;
  }
  .contact__link {
    display: block;
  }
  .page-article .item img {
    width: 60px;
  }
  .page-article .item__title {
    font-size: rem(10);
  }
}
