// ------------------------ FOX CONTACT
.page-114 {
  .article {
    margin: 5rem 0 0 0;
    text-align: center;
  }
  h2::after {
    margin: auto;
  }
}

/* ---------------------------------------- FOX CONTACT ---------------------------------------- */

.fox-container {
  text-align: center;
  background: white;
  padding: 0 0 6rem 0;

  .fox-form {
    width: 65rem;
    margin: 2rem auto 0 auto;
  }

  h2 {
    margin-bottom: 3rem;
  }
}

.required::after,
.asterisk::after {
  content: none !important;
}

.fox-item-html {
  margin-bottom: 2rem !important;
}

.fox-item {
  .controls {
    float: none !important;
  }

  input {
    line-height: 50px !important;
    height: 50px !important;
    padding-left: 15px !important;
    width: 100% !important;
  }

  textarea {
    height: 288px !important;
    padding-left: 15px !important;
    width: 100% !important;
    padding-top: 0.7rem !important;
  }

  &.fox-item-submit-fields .controls {
    width: 100% !important;
  }
}

.fox-item-submit {
  position: relative;

  .controls {
    text-align: left !important;
  }
}

.fox-item .btn {
  margin-top: 1rem;
  width: 100%;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1250px) {
  .fox-container {
    width: 100%;
    padding: 3rem 0 5rem 0;

    .fox-form {
      width: 95%;
    }

    h2 {
      margin-bottom: 3rem;
    }

    .fox-item {
      .controls {
        text-align: center !important;
      }
    }

    .fox-column {
      float: none !important;
      width: 100% !important;
      margin-left: 0 !important;
    }

    .fox-item input,
    .fox-item-dropdown-select-single,
    .fox-item-text-area textarea,
    .fox-item-recaptcha .controls,
    .fox-item-submit .controls {
      width: 100% !important;
    }

    .fox-item-recaptcha,
    .fox-item-submit {
      margin: 25px 0 !important;
    }

    .fox-form,
    .fox-form p {
      padding: 7px 0.5rem;
    }
  }
}

// ------------------------ MAP
#map {
  width: 100%;
  height: 500px;
}

@include breakpoint(700) {
  #map {
    height: 300px;
  }
}
