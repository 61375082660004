.main__center {
  margin-top: 20rem;
}

.page-114,
.page-144 {
  .main__center {
    margin-top: 3rem;
  }
}

.page-article {
  .content {
    margin-top: 5rem;
    padding: 5rem 0;
  }

  .btn {
    margin: auto;
    justify-content: center;
  }

  .content--1,
  .content--3,
  .content--4 {
    background-color: $gray-1;
  }

  .content--2 {
    .container {
      align-items: center;
      margin: 4rem auto;
    }
    i {
      color: $color-1;
      font-size: rem(20);
      margin-right: rem(5);
    }
    ul {
      margin-bottom: 2rem;
    }
  }

  .article__img--volets {
    box-shadow: none !important;
    margin: 0 !important;
  }

  .content--4 {
    .container {
      grid-template-columns: 1fr;
    }
    .article__img {
      margin: 2rem 0;
    }
    div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  .content--1,
  .content--3 {
    .article__img {
      position: relative;
      margin-top: -10rem;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    gap: 25px;
    justify-content: center;
  }
  .article__img {
    display: block;
    margin: auto;
    @include shadow-1;
  }

  //   ------------------------- item home
  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 3rem 0;
  }
  .item {
    text-align: center;
  }
  .item__title {
    display: block;
    text-transform: uppercase;
    font-size: rem(14);
    margin-top: rem(10);
  }

  .btn {
    margin-top: 3rem;
  }
}

@include breakpoint(1180) {
  .main__center {
    margin-top: 3rem;
  }
  .page-article .container {
    grid-template-columns: 1fr;
  }
  .page-article .content--1 .article__img,
  .page-article .content--3 .article__img {
    display: none;
  }
  .page-article .content {
    margin: 0;
  }
}
