html {
  scroll-behavior: smooth;
  height: 2000px;
}

body {
  word-break: break-word;
  background-color: $color-light;
  // padding-bottom: 10rem;
}

#wrapper {
  position: relative;
  margin: auto;
  min-height: 100vh;
  // overflow: hidden;
}

.container {
  max-width: rem(1280);
  margin: auto;
  padding: 0 rem(40);
}

.section {
  padding: rem(200) 0;
}

@include breakpoint(550) {
  .container {
    padding: 0 rem(16);
  }
}
