// ------------------------------------
// src: url('./base/fonts/./base/fonts/nomFont.woff2") format("woff2"),
// ------------------------------------

@font-face {
  font-family: "Avenir LT Pro";
  src: url("./base/fonts/AvenirLTPro-Roman.woff2") format("woff2"),
    url("./base/fonts/AvenirLTPro-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("./base/fonts/AvenirLTPro-Light.woff2") format("woff2"),
    url("./base/fonts/AvenirLTPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Pro 55 Roman";
  src: url("./base/fonts/AvenirLTPro-Heavy.woff2") format("woff2"),
    url("./base/fonts/AvenirLTPro-Heavy.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("./base/fonts/AvenirLTPro-Book.woff2") format("woff2"),
    url("./base/fonts/AvenirLTPro-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("./base/fonts/AvenirLTPro-Medium.woff2") format("woff2"),
    url("./base/fonts/AvenirLTPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Pro 65";
  src: url("./base/fonts/AvenirLTPro-Black.woff2") format("woff2"),
    url("./base/fonts/AvenirLTPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
