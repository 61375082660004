// -------------------------------------- ALL

.espace-client {
  .main {
    max-width: 980px;
    margin: 3rem auto;
  }
  .main__center {
    display: grid;
    grid-template-columns: 220px 1fr;
    margin: 1rem 0;
    gap: 20px;
  }

  aside {
    border-right: 2px solid $gray-3;
  }

  #adminForm {
    margin: 0;
  }
}

// ------------------------------ Popover

.popover,
.tooltip {
  display: none !important;
}

// ------------------------------ Btn
.btn.dropdown-toggle {
  display: none;
}

.btn.btn-default,
.btn.jmodedit {
  background-color: $color-1 !important;
  border: 0 !important;
  text-shadow: none !important;
  background-image: none;
  font-size: rem(16);
  i {
    display: none;
  }
  .icon-edit::before {
    color: white !important;
    font-size: rem(20) !important;
  }
  &:hover {
    color: white !important;
  }
}

// ------------------------------ main top

.main__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid $gray-3;
  padding-bottom: 1rem;

  .espace-client__title {
    font-size: rem(32);
    text-transform: uppercase;
    font-weight: bold;
  }
}

// -------------------------------------- ACCUEIL

.espace-client__dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  gap: 20px;

  .card {
    border: 1px solid $gray-3;
    // height: 220px;
    a {
      display: block;
      padding: 2rem 1rem;
    }
    i {
      display: block;
      margin: auto;
      text-align: center;
      font-size: 3rem;
      color: $color-1;
    }

    .card__title {
      display: block;
      text-align: center;
      text-transform: uppercase;
      margin: 1rem 0;
      font-weight: 500;
    }

    p {
      text-align: center;
      font-size: rem(16);
    }
  }
}
