.all-hero {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin: 3rem 0 5rem 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    height: 420px;
    left: 0;
    bottom: -15px;
    width: 100%;
    background-color: $gray-1;
  }

  h1 {
    margin-bottom: 4rem;
  }

  .hero__left {
    margin-left: 200px;
  }

  .hero__right img {
    @include shadow-1;
  }

  .btn {
    margin-top: 2rem;
  }

  .hero__garanty {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    img {
      margin-left: 2rem;
    }
  }
}

@include breakpoint(1400) {
  .all-hero .hero__left {
    margin-left: 3rem;
  }
}

@include breakpoint(980) {
  .all-hero {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 1rem;
  }
  .all-hero .hero__left {
    margin: 0 0 2rem 0;
  }
}
