.menu__main {
  // ------------------------------------------- NAV CHILD
  .parent {
    position: relative;
    display: flex;
    align-items: center;
    .nav-header::after {
      @include icon("\f078");
      font-size: rem(20);
      margin-left: rem(10);
      font-weight: 600;
    }
  }

  .nav-child {
    position: absolute;
    opacity: 0;
    z-index: -1;
    left: 0;
    top: 100%;
    width: 400px;
    background-color: $gray-2;
    padding: 5px 5px 5px 15px;

    li {
      position: relative;
      margin: 0;

      border-bottom: 1px solid $gray-4;
      &:last-child {
        border: 0;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-size: rem(18);

      &::before {
        @include icon("\f054");
        color: $color-1;
        margin-right: rem(7);
        font-size: rem(11);
      }
    }
  }
  .nav-child--active {
    opacity: 1;
    z-index: 10;
    transition: opacity 150ms ease;
  }

  // .item-107 .nav-child {
  //   width: 400px;
  // }
  .item-111 .nav-child {
    width: 250px;
  }
  .item-140 .nav-child {
    width: 250px;
  }

  // ------------------------------------------- EO NAV CHILD
}

.menu__main {
  display: flex;
  align-items: center;
  justify-content: center;

  //------------------------------------------------------ li
  li {
    margin: 0 1rem;
  }

  .item-101 {
    display: none;
  }

  //------------------------------------------------------ a
  a,
  .nav-header {
    font-size: rem(16);
    font-weight: 400;
    text-transform: uppercase;
    transition: all 200ms;
    cursor: pointer;
    padding: 10px 0;
  }

  li.active a {
    color: $color-1;
  }
  //------------------------------------------------------ hover
  a:hover {
    color: $color-1;
  }
}

//------------------------------------------------------ bouton menu
#menu__btn {
  display: none;
  margin-right: 2rem;
  position: relative;
  cursor: pointer;
  z-index: 99;
  outline: 0;
  background-color: transparent;
  border: 0;
  .menu__mobile__title {
    display: inline-flex;
    text-transform: uppercase;
    color: $color-1;
    line-height: 0;
    font-family: $font-normal;
  }
}

.menu__bar {
  position: relative;
  display: block;
  width: 40px;
  height: 3px;
  background-color: $color-1;
  margin-bottom: 7px;
  border-radius: 2px;
  transition: all 150ms;
}

#menu__btn.menu__btn--active {
  position: relative;
  .menu__bar--1 {
    opacity: 0;
  }
  .menu__bar--2 {
    transform: rotate(45deg);
  }
  .menu__bar--3 {
    transform: rotate(-45deg);
    top: -10px;
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(1450) {
  .header__menu {
    display: flex;
    justify-content: end;
  }
  #menu__btn {
    display: block;
  }
  .menu__main {
    position: fixed;
    top: 0;
    right: -300px;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 4rem;
    align-items: stretch;
    width: 300px;
    height: 100vh;
    background-color: $gray-4;
    transition: all 200ms;
    z-index: -1;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: inherit;
    // -------------------------- li
    li {
      padding: rem(10) 0;
    }
    .item-101 {
      display: block;
    }

    .parent {
      display: block;
    }
    .nav-child {
      top: 0;
      left: 0;

      width: auto;
    }

    .nav-child--active {
      position: relative;
      opacity: 1;
      li {
        padding: 5px 0;
      }
      a {
        font-size: rem(16);
        padding: 7px 0;
      }
    }
  }

  // -------------------------- menu active
  .header__menu--active .menu__main {
    right: 0;
    z-index: 9998;
  }
}
