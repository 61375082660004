.header {
  padding: 0 rem(40);
  background-color: $gray-1;
}

.header__container {
  display: grid;
  grid-template-columns: 315px 1fr 150px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  // height: 100px;
  padding: 2rem 0;
}

// ------------------------ Logo
.header__logo {
  display: flex;
  justify-content: center;
}

// ------------------------ Sociaux

.header__social {
  display: flex;
  align-items: center;

  .social__title {
    display: block;
    text-transform: uppercase;
    font-size: rem(14);
    margin-bottom: rem(5);
  }
}

.header__phone {
  margin-right: 2rem;
  font-size: rem(22);
  line-height: 1;

  .phone__number {
    font-weight: 500;
    font-size: rem(26);
  }
}

.header__reseaux {
  i {
    font-size: rem(30);
    margin-left: rem(5);
  }
}

.header__devis {
  a {
    border: 1px solid $color-1;
    color: $color-1;
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem(15);
    padding: 7px 14px;
  }
}

// ----------------------- INFOS

.header__infos {
  position: absolute;
  display: flex;
  gap: 20px;
  top: 0;
  right: 20%;
  i {
    margin-right: rem(4);
  }
}

.header__catalogue {
  color: white;
  background-color: $color-1;
  padding: 5px 10px;
}

.header__contact {
  display: flex;
  align-items: center;
  color: white;
  background-color: $color-dark;
  a {
    padding: 5px 10px;
  }
  a:first-of-type {
    border-right: 1px solid $gray-2;
  }
}

@include breakpoint(1700) {
  .header__container {
    grid-template-columns: 250px 1fr 150px;
  }
  .menu__main li {
    margin: 0 0.7rem;
  }
  .menu__main a,
  .menu__main .nav-header {
    font-size: rem(14);
  }
}

@include breakpoint(1000) {
  .header__container {
    grid-template-columns: 200px 1fr 150px;
    padding: 3rem 0 2rem 0;
  }
  .header__infos {
    display: flex;
    justify-content: center;
    right: 0;
    width: 100%;
  }
}

@include breakpoint(550) {
  .header {
    padding: 0 1rem;
  }
  .header__container {
    grid-template-columns: 200px 1fr;
    padding: 3rem 0 1rem 0;
    gap: 0;
  }
  .header__catalogue,
  .btn--devis {
    display: none;
  }
}
