.page-101 .blog-featured {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  gap: 20px;
  margin: $mg-8 0;
  //   ---------------------- item
  .items-row {
    position: relative;
    @include shadow-1;
  }
  //   ---------------------- icon modifier
  .icons {
    position: absolute;
    top: 0;
    left: 0;
  }
  //   ---------------------- image
  .item-image {
    width: 100%;
    height: 200px;
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  //   ---------------------- image
  .item-title {
    font-size: rem(18);
    padding: 0 1rem;
    color: $color-1;
    font-weight: 500;
  }
  //   ---------------------- texte
  p {
    padding: 7px 16px;
  }
}

@include breakpoint(550) {
  .page-101 .blog-featured {
  }
}
